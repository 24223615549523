import cmpFaq from '../vue/common/Faq.vue';
import cmpProductCards from '../vue/common/ProductCards.vue';

window.FrontPage = (function() {
	// search event
	const initSearch = () => {
		const searchInput = document.getElementById('search-input');
		const searchButton = document.getElementById('search-button');

		const navigateToSearch = (event) => {
			event.preventDefault();

			const searchTerm = encodeURIComponent(searchInput.value.trim());
			if (searchTerm) {
				const url = `/templates/term/${searchTerm}/`;
				window.open(url, '_blank');
			}
		};

		searchInput.addEventListener('keydown', (event) => {
			if (event.key === 'Enter') {
				navigateToSearch(event);
			}
		});

		searchButton.addEventListener('click', navigateToSearch);
	};


	// product cards - new
	const initProductCardsNew = () => {
		const component = Vue.createApp(cmpProductCards, {
			headerName : 'New',
			buttonUrl : '/templates/',
			oPackages: window.frontPageInfo.packagesNew
		});

		return component.mount('#component-product-cards-new');
	};


	// product cards - bestsellers
	const initProductCardsBest = () => {
		const component = Vue.createApp(cmpProductCards, {
			headerName : 'Bestsellers',
			buttonUrl : '/templates/',
			oPackages: window.frontPageInfo.packagesBest
		});

		return component.mount('#component-product-cards-best');
	};


	// product cards - sale
	const initProductCardsSale = () => {
		const component = Vue.createApp(cmpProductCards, {
			headerName : 'Sale',
			buttonUrl : '/templates/',
			oPackages: window.frontPageInfo.packagesSale
		});

		return component.mount('#component-product-cards-sale');
	};


	// faq
	const initFaq = () => {
		const component = Vue.createApp(cmpFaq, {
			popupMode: false
		});

		return component.mount('#component-faq');
	};


	// init search event
	document.addEventListener('DOMContentLoaded', initSearch);

	// init vue components
	let retValue = {
		cmpProductCardsNew : initProductCardsNew(),
		cmpProductCardsBest : initProductCardsBest(),
		cmpProductCardsSale : initProductCardsSale(),
		cmpFaq : initFaq()
	};

	// delete variable from window
	delete window.frontPageInfo;

	// return
	return retValue;
})();
