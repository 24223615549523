<template>
	<div class="timer-container" v-cloak>
		<span v-show="moreThanOneDay">{{displayDays}} {{displayHours}}:{{displayMinutes}}:{{displaySeconds}}</span>
		<span v-show="lessThanOneDay">{{displayHours}}:{{displayMinutes}}:{{displaySeconds}}</span>
	</div>
</template>




<script>
	const HOURS_PER_DAY    = 24;
	const SECS_PER_DAY     = 86400;
	const SECS_PER_HOUR    = 3600;
	const SECS_PER_MINUTE  = 60;
	const MSECS_PER_SECOND = 1000;

	export default {
		data: () => ({
			idTimeout: 0,
			idInterval: 0,
			nrSeconds : 0,
			fraction: 0
		}),
		props: {
			seconds: { type: Number, required: false, default: 0 },
		},
		mounted() {
			this.initSeconds();
			this.restart();
		},
		methods: {
			initSeconds() {
				this.nrSeconds = (this.seconds >= 0) ? this.seconds : 0;
				this.fraction = this.nrSeconds % 1;
				this.nrSeconds = Math.floor(this.nrSeconds);
			},
			_startInterval() {
				this.idInterval = setInterval(() => {
					if (this.nrSeconds > 0)
					{
						this.nrSeconds--;
					}

					if (this.nrSeconds == 0) {
						this.stop();
						this.$emit('ended');
					}
				}, MSECS_PER_SECOND);
			},
			startInterval() {
				this.idTimeout = setTimeout(() => {
					this._startInterval();
				}, this.fraction);
			},
			restart() {
				this.stop();

				var totalTime = (this.nrSeconds + this.fraction);

				if (totalTime > 0) {
					this.startInterval();
				}
			},
			stop() {
				clearTimeout(this.idTimeout);
				this.idTimeout = 0;

				clearInterval(this.idInterval);
				this.idInterval = 0;
			}
		},
		computed: {
			displayDays() {
				var nrDays = Math.floor(this.nrSeconds / SECS_PER_DAY);
				return `${nrDays}D`;
			},
			displayHours() {
				var nrHours = Math.floor((this.nrSeconds / SECS_PER_HOUR) % HOURS_PER_DAY);
				var strHours = new String(nrHours).padStart(2, '0');
				return strHours;
			},
			displayMinutes() {
				var nrMins = Math.floor((this.nrSeconds / SECS_PER_MINUTE) % SECS_PER_MINUTE);
				var strMins = new String(nrMins).padStart(2, '0');
				return strMins;
			},
			displaySeconds() {
				var nrSecs = Math.floor(this.nrSeconds % SECS_PER_MINUTE);
				var strSecs = new String(nrSecs).padStart(2, '0');
				return strSecs;
			},
			moreThanOneDay() {
				return (this.nrSeconds >= SECS_PER_DAY);
			},
			lessThanOneDay() {
				return !this.moreThanOneDay;
			}
		},
		watch: {
			seconds(newVal, oldVal) {
				this.initSeconds();
				this.restart();
			}
		}
	};
</script>




<style scoped>
	[v-cloak] {
		display:none;
	}

	div.timer-container {
		display:inline-block;
	}
</style>