<template>
	<div class="col-12 mb-3" v-show="moreThanZero">
		<div class="row justify-content-between align-items-center">
			<div class="col-auto">
				<h2 class="h4 mb-md-0">{{headerName}}</h2>
			</div>
			<div class="col-auto">
				<button class="btn btn-light" @click="browseBundles">Browse bundles</button>
			</div>
		</div>
	</div>
	<div v-for="(pck, i) in _oPackages" class="col-sm-6 col-lg-3 mb-3 mb-md-4" :key="pck.id">
		<a class="product-card card shadow-sm d-block text-decoration-none" :href="getUrl(pck)" target="_blank">
			<div class="ratio ratio-3x2 rounded-top">
				<img :src="getPreviewImageUrl(pck)" class="bg-image-top" :alt="getPreviewImageAlt(pck)" :width="getImagePreviewWidth(pck)" :height="getImagePreviewHeight(pck)" loading="lazy" />
			</div>
			<div class="card-body p-md-4">
				<h3 class="product-card-title h6 text-black">
					<span>{{pck.name}}</span>
				</h3>
				<div class="product-card-actions d-flex align-items-center justify-content-between">
					<div class="badges">
						<div class="badge sale" v-if="isDiscounted(pck)">SALE</div>
						<div class="badge" :class="clsBadgeCredits(pck)">
							<!--<i class="icon-coins me-1"></i>-->
							<span><!--&nbsp;-->{{toDollarAmount(pck.actualNrCredits)}}</span>
						</div>
					</div>
					<timer :seconds="getDiscountNrSeconds(pck)" @ended="timerEnded(pck)" v-if="isDiscounted(pck)"></timer>
				</div>
			</div>
		</a>
	</div>
</template>




<script>
	import timer from './Timer.vue';

	export default {
		components: {
			timer
		},
		data: () => ({
			_oPackages : {}
		}),
		props: {
			headerName : { type: String, required: false, default: () => ('Bundles')},
			buttonUrl : { type: String, required: false, default: () => ('/templates/')},
			oPackages: { type: Array, required: false, default: () => ([])},
		},
		created() {
			this._oPackages = this.oPackages;
		},
		mounted() {
		},
		methods: {
			browseBundles() {
				window.open(this.buttonUrl, '_blank');
			},
			getUrl(pck) {
				return pck?.url;
			},
			getFirstImage(pck) {
				return pck?.images?.[0];
			},
			getPreviewImageUrl(pck) {
				var image = this.getFirstImage(pck);
				return common.versionedImageUrl(image?.img500Url, image?.imgOriSha1);
			},
			getPreviewImageAlt(pck) {
				return pck?.name;
			},
			getImagePreviewWidth(pck) {
				var image = this.getFirstImage(pck);
				return image?.img500Width ?? 0;
			},
			getImagePreviewHeight(pck) {
				var image = this.getFirstImage(pck);
				return image?.img500Height ?? 0;
			},
			getDiscountNrSeconds(pck) {
				return pck?.discountPeriods?.[0]?.endsInNrSeconds ?? 0;
			},
			isDiscounted(pck) {
				return (pck?.discountPeriods?.length > 0);
			},
			clsBadgeCredits(pck) {
				let discounted = this.isDiscounted(pck);

				return {
					'regular-price' : !discounted,
					'discount-price' : discounted
				};
			},
			timerEnded(pck) {
				pck.discountPeriods = [];
			},
			toDollarAmount(nrCredits) {
				return `$${nrCredits.toNrDecimals(2, 2)}`;
			}
		},
		computed: {
			moreThanZero() {
				return (this._oPackages.length > 0);
			}
		},
		watch: {
		}
	};
</script>




<style scoped>@import '../../scss/common/parts/Button.scss'</style>
<style scoped>@import '../../scss/common/parts/Extra.scss'</style>
<style scoped>@import '../../scss/common/parts/Override.scss'</style>

<style scoped>
	[v-cloak] {
		display:none;
	}

	.accordion-body p:last-child {
		margin-bottom: 0;
	}

	table, th, td {
		border:2px solid rgb(67, 56, 202);
		border-collapse:collapse;
		padding:10px 20px;
	}

	:deep(.timer-container) {
		color: #13795b;
		font-weight: 600;
	}
</style>