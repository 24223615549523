<template>
	<section class="faq bg-light py-4" :class="clsSection()">
		<div class="container">
			<div class="row">
				<div class="col-12">
					<h2 class="h4 mb-4">Frequently Asked Questions</h2>
					<div class="accordion accordion-flush" :class="clsAccordionFlush()" id="faq">
						<div class="accordion-item">
							<h2 class="accordion-header" id="faq2">
								<button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapse2" aria-expanded="false" aria-controls="flush-collapse2">
									Is it a one-time purchase?
								</button>
							</h2>
							<div id="flush-collapse2" class="accordion-collapse collapse" aria-labelledby="faq2" data-bs-parent="#faq">
								<div class="accordion-body">
									<p>It certainly is. Your template bundle will be yours to keep, forever.</p>
								</div>
							</div>
						</div>
						<div class="accordion-item">
							<h2 class="accordion-header" id="faq1">
								<button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapse1" aria-expanded="false" aria-controls="flush-collapse1">
									When do I receive my product?
								</button>
							</h2>
							<div id="flush-collapse1" class="accordion-collapse collapse" aria-labelledby="faq1" data-bs-parent="#faq">
								<div class="accordion-body">
									<p>Right after you buy a template bundle, a link to it will appear. It will always be accessible in your 'My Bundles' overview.</p>
								</div>
							</div>
						</div>
						<div class="accordion-item">
							<h2 class="accordion-header" id="faq3">
								<button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapse3" aria-expanded="false" aria-controls="flush-collapse3">
									Do I need graphics editing skills?
								</button>
							</h2>
							<div id="flush-collapse3" class="accordion-collapse collapse" aria-labelledby="faq3" data-bs-parent="#faq">
								<div class="accordion-body">
									<p>Not really. Most of our design bundles are Canva bundles. Canva offers notoriously user friendly graphics editing tools.</p>
									<p>Some of our older bundles are Photoshop bundles. For those, you need some skills. Please read the bundle descriptions before purchasing.</p>
								</div>
							</div>
						</div>
						<div class="accordion-item">
							<h2 class="accordion-header" id="faq4">
								<button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapse4" aria-expanded="false" aria-controls="flush-collapse4">
									How much time will editing take?
								</button>
							</h2>
							<div id="flush-collapse4" class="accordion-collapse collapse" aria-labelledby="faq4" data-bs-parent="#faq">
								<div class="accordion-body">
									<p>We design our products to let you save as much time as possible. With Canva, your templates can be easily edited with a small number of clicks. You can schedule your posts weeks ahead in a matter of minutes!</p>
								</div>
							</div>
						</div>
						<div class="accordion-item">
							<h2 class="accordion-header" id="faq5">
								<button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapse5" aria-expanded="false" aria-controls="flush-collapse5">
									Can I use my own branded logo?
								</button>
							</h2>
							<div id="flush-collapse5" class="accordion-collapse collapse" aria-labelledby="faq5" data-bs-parent="#faq">
								<div class="accordion-body">
									<p>Absolutely. You can add any kind of imagery you want. Our templates are fully customizable.</p>
								</div>
							</div>
						</div>
						<div class="accordion-item">
							<h2 class="accordion-header" id="faq6">
								<button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapse6" aria-expanded="false" aria-controls="flush-collapse6">
									Can I lose my products?
								</button>
							</h2>
							<div id="flush-collapse6" class="accordion-collapse collapse" aria-labelledby="faq6" data-bs-parent="#faq">
								<div class="accordion-body">
									<p>No. They will always remain accessible in your 'My Bundles' overview. You can also download them and store backup copies of your own, if you like.</p>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</section>
</template>




<script>
	export default {
		data: () => ({
		}),
		props: {
			credits: { type: Array, required: false, default: () => ([
				{ nrCredits : 10,  cost : 1000,  costPerCredit: 100 },
				{ nrCredits : 25,  cost : 2250,  costPerCredit:  90 },
				{ nrCredits : 50,  cost : 4000,  costPerCredit:  80 },
				{ nrCredits : 100, cost : 7000,  costPerCredit:  70 },
				{ nrCredits : 250, cost : 15000, costPerCredit:  60 },
				{ nrCredits : 500, cost : 25000, costPerCredit:  50 }
			])},
			popupMode : { type: Boolean, required: false, default: false }
		},
		mounted() {
		},
		methods: {
			usdCentsDisplay(usdCents) {
				var dollars = (usdCents / 100);
				return `$${dollars.toNrDecimals(0, 2)}`;
			},
			clsSection() {
				return {
					'py-sm-5' : !this.popupMode
				};
			},
			clsAccordionFlush() {
				return {
					'mb-sm-5' : !this.popupMode
				};
			}
		},
		computed: {
		},
		watch: {
		}
	};
</script>




<style scoped>@import '../../scss/common/parts/Accordion.scss'</style>
<style scoped>@import '../../scss/common/parts/Button.scss'</style>
<style scoped>@import '../../scss/common/parts/Extra.scss'</style>
<style scoped>@import '../../scss/common/parts/Override.scss'</style>

<style scoped>
	[v-cloak] {
		display:none;
	}

	.accordion-body p:last-child {
		margin-bottom: 0;
	}

	table, th, td {
		border:2px solid rgb(67, 56, 202);
		border-collapse:collapse;
		padding:10px 20px;
	}

</style>